@use "~@picocss/pico/scss/colors" as *;

$utility-width: 612px;

.utility-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .utility-inner {
    width: $utility-width;
    max-width: calc(100vw - 4rem);
  }
}

.utility-co2-volume-group {
  .utility-co2-volume-input {
    width: 5rem;
  }
}

.positive-difference {
  color: $green-500;
}

.negative-difference {
  color: $red-500;
}
