.app-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  hgroup {
    max-width: 650px;
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      padding-bottom: 1rem;

      span {
        white-space: nowrap;
      }
    }
  }

  .app-reviews {
    display: inline-block;
    padding: 0.5rem;
    border-radius: var(--pico-border-radius);
    background: var(--pico-code-background-color);
    color: var(--pico-code-color);
    font-weight: var(--pico-font-weight);
    line-height: initial;
    margin-bottom: 2rem;
  }

  .app-badge {
    margin-bottom: 2rem;

    picture {
      display: block;
      width: 150px;
      height: 50px;
      // Disable text selection for Safari Live Text
      -webkit-user-select: none !important;
      user-select: none !important;
    }
  }

  .app-slideshow {
    max-width: 760px;
  }
}
