@use "sass:map";
@use "../settings" as *;

@if map.get($modules, "content/figure") {
  /**
   * Figure
   */

  #{$parent-selector} figure {
    display: block;
    margin: 0;
    padding: 0;

    figcaption {
      padding: calc(var(#{$css-var-prefix}spacing) * 0.5) 0;
      color: var(#{$css-var-prefix}muted-color);
    }
  }
}
