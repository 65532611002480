// B&W
$black: #000;
$white: #fff;

// Red
$red-950: #1c0d06;
$red-900: #30130a;
$red-850: #45150c;
$red-800: #5c160d;
$red-750: #72170f;
$red-700: #861d13;
$red-650: #9b2318;
$red-600: #af291d;
$red-550: #c52f21;
$red-500: #d93526;
$red-450: #ee402e;
$red-400: #f06048;
$red-350: #f17961;
$red-300: #f38f79;
$red-250: #f5a390;
$red-200: #f5b7a8;
$red-150: #f6cabf;
$red-100: #f8dcd6;
$red-50: #faeeeb;
$red: $red-550;

// Pink
$pink-950: #25060c;
$pink-900: #380916;
$pink-850: #4b0c1f;
$pink-800: #5f0e28;
$pink-750: #740f31;
$pink-700: #88143b;
$pink-650: #9d1945;
$pink-600: #b21e4f;
$pink-550: #c72259;
$pink-500: #d92662;
$pink-450: #f42c6f;
$pink-400: #f6547e;
$pink-350: #f7708e;
$pink-300: #f8889e;
$pink-250: #f99eae;
$pink-200: #f9b4be;
$pink-150: #f9c8ce;
$pink-100: #f9dbdf;
$pink-50: #fbedef;
$pink: $pink-500;

// Fuchsia
$fuchsia-950: #230518;
$fuchsia-900: #360925;
$fuchsia-850: #480b33;
$fuchsia-800: #5c0d41;
$fuchsia-750: #700e4f;
$fuchsia-700: #84135e;
$fuchsia-650: #98176d;
$fuchsia-600: #ac1c7c;
$fuchsia-550: #c1208b;
$fuchsia-500: #d9269d;
$fuchsia-450: #ed2aac;
$fuchsia-400: #f748b7;
$fuchsia-350: #f869bf;
$fuchsia-300: #f983c7;
$fuchsia-250: #fa9acf;
$fuchsia-200: #f9b1d8;
$fuchsia-150: #f9c6e1;
$fuchsia-100: #f9daea;
$fuchsia-50: #fbedf4;
$fuchsia: $fuchsia-550;

// Purple
$purple-950: #1e0820;
$purple-900: #2d0f33;
$purple-850: #3d1545;
$purple-800: #4d1a57;
$purple-750: #5e206b;
$purple-700: #6f277d;
$purple-650: #802e90;
$purple-600: #9236a4;
$purple-550: #aa40bf;
$purple-500: #b645cd;
$purple-450: #c652dc;
$purple-400: #cd68e0;
$purple-350: #d47de4;
$purple-300: #db90e8;
$purple-250: #e2a3eb;
$purple-200: #e7b6ee;
$purple-150: #edc9f1;
$purple-100: #f2dcf4;
$purple-50: #f8eef9;
$purple: $purple-600;

// Violet
$violet-950: #190928;
$violet-900: #251140;
$violet-850: #321856;
$violet-800: #3f1e6d;
$violet-750: #4d2585;
$violet-700: #5b2d9c;
$violet-650: #6935b3;
$violet-600: #7540bf;
$violet-550: #8352c5;
$violet-500: #9062ca;
$violet-450: #9b71cf;
$violet-400: #a780d4;
$violet-350: #b290d9;
$violet-300: #bd9fdf;
$violet-250: #c9afe4;
$violet-200: #d3bfe8;
$violet-150: #decfed;
$violet-100: #e8dff2;
$violet-50: #f3eff7;
$violet: $violet-600;

// Indigo
$indigo-950: #110b31;
$indigo-900: #181546;
$indigo-850: #1f1e5e;
$indigo-800: #272678;
$indigo-750: #2f2f92;
$indigo-700: #3838ab;
$indigo-650: #4040bf;
$indigo-600: #524ed2;
$indigo-550: #655cd6;
$indigo-500: #7569da;
$indigo-450: #8577dd;
$indigo-400: #9486e1;
$indigo-350: #a294e5;
$indigo-300: #b0a3e8;
$indigo-250: #bdb2ec;
$indigo-200: #cac1ee;
$indigo-150: #d8d0f1;
$indigo-100: #e5e0f4;
$indigo-50: #f2f0f9;
$indigo: $indigo-600;

// Blue
$blue-950: #080f2d;
$blue-900: #0c1a41;
$blue-850: #0e2358;
$blue-800: #0f2d70;
$blue-750: #0f3888;
$blue-700: #1343a0;
$blue-650: #184eb8;
$blue-600: #1d59d0;
$blue-550: #2060df;
$blue-500: #3c71f7;
$blue-450: #5c7ef8;
$blue-400: #748bf8;
$blue-350: #8999f9;
$blue-300: #9ca7fa;
$blue-250: #aeb5fb;
$blue-200: #bfc3fa;
$blue-150: #d0d2fa;
$blue-100: #e0e1fa;
$blue-50: #f0f0fb;
$blue: $blue-550;

// Azure
$azure-950: #04121d;
$azure-900: #061e2f;
$azure-850: #052940;
$azure-800: #033452;
$azure-750: #014063;
$azure-700: #014c75;
$azure-650: #015887;
$azure-600: #02659a;
$azure-550: #0172ad;
$azure-500: #017fc0;
$azure-450: #018cd4;
$azure-400: #029ae8;
$azure-350: #01aaff;
$azure-300: #51b4ff;
$azure-250: #79c0ff;
$azure-200: #9bccfd;
$azure-150: #b7d9fc;
$azure-100: #d1e5fb;
$azure-50: #e9f2fc;
$azure: $azure-550;

// Cyan
$cyan-950: #041413;
$cyan-900: #051f1f;
$cyan-850: #052b2b;
$cyan-800: #043737;
$cyan-750: #014343;
$cyan-700: #015050;
$cyan-650: #025d5d;
$cyan-600: #046a6a;
$cyan-550: #047878;
$cyan-500: #058686;
$cyan-450: #059494;
$cyan-400: #05a2a2;
$cyan-350: #0ab1b1;
$cyan-300: #0ac2c2;
$cyan-250: #0ccece;
$cyan-200: #25dddd;
$cyan-150: #3deceb;
$cyan-100: #58faf9;
$cyan-50: #c3fcfa;
$cyan: $cyan-550;

// Jade
$jade-950: #04140c;
$jade-900: #052014;
$jade-850: #042c1b;
$jade-800: #033823;
$jade-750: #00452b;
$jade-700: #015234;
$jade-650: #005f3d;
$jade-600: #006d46;
$jade-550: #007a50;
$jade-500: #00895a;
$jade-450: #029764;
$jade-400: #00a66e;
$jade-350: #00b478;
$jade-300: #00c482;
$jade-250: #00cc88;
$jade-200: #21e299;
$jade-150: #39f1a6;
$jade-100: #70fcba;
$jade-50: #cbfce1;
$jade: $jade-550;

// Green
$green-950: #0b1305;
$green-900: #131f07;
$green-850: #152b07;
$green-800: #173806;
$green-750: #1a4405;
$green-700: #205107;
$green-650: #265e09;
$green-600: #2c6c0c;
$green-550: #33790f;
$green-500: #398712;
$green-450: #409614;
$green-400: #47a417;
$green-350: #4eb31b;
$green-300: #55c21e;
$green-250: #5dd121;
$green-200: #62d926;
$green-150: #77ef3d;
$green-100: #95fb62;
$green-50: #d7fbc1;
$green: $green-500;

// Lime
$lime-950: #101203;
$lime-900: #191d03;
$lime-850: #202902;
$lime-800: #273500;
$lime-750: #304100;
$lime-700: #394d00;
$lime-650: #435a00;
$lime-600: #4d6600;
$lime-550: #577400;
$lime-500: #628100;
$lime-450: #6c8f00;
$lime-400: #779c00;
$lime-350: #82ab00;
$lime-300: #8eb901;
$lime-250: #99c801;
$lime-200: #a5d601;
$lime-150: #b2e51a;
$lime-100: #c1f335;
$lime-50: #defc85;
$lime: $lime-200;

// Yellow
$yellow-950: #141103;
$yellow-900: #1f1c02;
$yellow-850: #2b2600;
$yellow-800: #363100;
$yellow-750: #423c00;
$yellow-700: #4e4700;
$yellow-650: #5b5300;
$yellow-600: #685f00;
$yellow-550: #756b00;
$yellow-500: #827800;
$yellow-450: #908501;
$yellow-400: #9e9200;
$yellow-350: #ad9f00;
$yellow-300: #bbac00;
$yellow-250: #caba01;
$yellow-200: #d9c800;
$yellow-150: #e8d600;
$yellow-100: #f2df0d;
$yellow-50: #fdf1b4;
$yellow: $yellow-100;

// Amber
$amber-950: #161003;
$amber-900: #231a03;
$amber-850: #312302;
$amber-800: #3f2d00;
$amber-750: #4d3700;
$amber-700: #5b4200;
$amber-650: #694d00;
$amber-600: #785800;
$amber-550: #876400;
$amber-500: #977000;
$amber-450: #a77c00;
$amber-400: #b78800;
$amber-350: #c79400;
$amber-300: #d8a100;
$amber-250: #e8ae01;
$amber-200: #ffbf00;
$amber-150: #fecc63;
$amber-100: #fddea6;
$amber-50: #fcefd9;
$amber: $amber-200;

// Pumpkin
$pumpkin-950: #180f04;
$pumpkin-900: #271805;
$pumpkin-850: #372004;
$pumpkin-800: #482802;
$pumpkin-750: #593100;
$pumpkin-700: #693a00;
$pumpkin-650: #7a4400;
$pumpkin-600: #8b4f00;
$pumpkin-550: #9c5900;
$pumpkin-500: #ad6400;
$pumpkin-450: #bf6e00;
$pumpkin-400: #d27a01;
$pumpkin-350: #e48500;
$pumpkin-300: #ff9500;
$pumpkin-250: #ffa23a;
$pumpkin-200: #feb670;
$pumpkin-150: #fcca9b;
$pumpkin-100: #fcdcc1;
$pumpkin-50: #fceee3;
$pumpkin: $pumpkin-300;

// Orange
$orange-950: #1b0d06;
$orange-900: #2d1509;
$orange-850: #411a0a;
$orange-800: #561e0a;
$orange-750: #6b220a;
$orange-700: #7f270b;
$orange-650: #942d0d;
$orange-600: #a83410;
$orange-550: #bd3c13;
$orange-500: #d24317;
$orange-450: #e74b1a;
$orange-400: #f45d2c;
$orange-350: #f56b3d;
$orange-300: #f68e68;
$orange-250: #f8a283;
$orange-200: #f8b79f;
$orange-150: #f8cab9;
$orange-100: #f9dcd2;
$orange-50: #faeeea;
$orange: $orange-500;

// Sand
$sand-950: #111110;
$sand-900: #1c1b19;
$sand-850: #272622;
$sand-800: #32302b;
$sand-750: #3d3b35;
$sand-700: #49463f;
$sand-650: #55524a;
$sand-600: #615e55;
$sand-550: #6e6a60;
$sand-500: #7b776b;
$sand-450: #888377;
$sand-400: #959082;
$sand-350: #a39e8f;
$sand-300: #b0ab9b;
$sand-250: #beb8a7;
$sand-200: #ccc6b4;
$sand-150: #dad4c2;
$sand-100: #e8e2d2;
$sand-50: #f2f0ec;
$sand: $sand-200;

// Grey
$grey-950: #111111;
$grey-900: #1b1b1b;
$grey-850: #262626;
$grey-800: #303030;
$grey-750: #3b3b3b;
$grey-700: #474747;
$grey-650: #525252;
$grey-600: #5e5e5e;
$grey-550: #6a6a6a;
$grey-500: #777777;
$grey-450: #808080;
$grey-400: #919191;
$grey-350: #9e9e9e;
$grey-300: #ababab;
$grey-250: #b9b9b9;
$grey-200: #c6c6c6;
$grey-150: #d4d4d4;
$grey-100: #e2e2e2;
$grey-50: #f1f1f1;
$grey: $grey-300;

// Zinc
$zinc-950: #0f1114;
$zinc-900: #191c20;
$zinc-850: #23262c;
$zinc-800: #2d3138;
$zinc-750: #373c44;
$zinc-700: #424751;
$zinc-650: #4d535e;
$zinc-600: #5c6370;
$zinc-550: #646b79;
$zinc-500: #6f7887;
$zinc-450: #7b8495;
$zinc-400: #8891a4;
$zinc-350: #969eaf;
$zinc-300: #a4acba;
$zinc-250: #b3b9c5;
$zinc-200: #c2c7d0;
$zinc-150: #d1d5db;
$zinc-100: #e0e3e7;
$zinc-50: #f0f1f3;
$zinc: $zinc-550;

// Slate
$slate-950: #0e1118;
$slate-900: #181c25;
$slate-850: #202632;
$slate-800: #2a3140;
$slate-750: #333c4e;
$slate-700: #3d475c;
$slate-650: #48536b;
$slate-600: #525f7a;
$slate-550: #5d6b89;
$slate-500: #687899;
$slate-450: #7385a9;
$slate-400: #8191b5;
$slate-350: #909ebe;
$slate-300: #a0acc7;
$slate-250: #b0b9d0;
$slate-200: #bfc7d9;
$slate-150: #cfd5e2;
$slate-100: #dfe3eb;
$slate-50: #eff1f4;
$slate: $slate-600;

$colors: (
  red: (
    950: $red-950,
    900: $red-900,
    850: $red-850,
    800: $red-800,
    750: $red-750,
    700: $red-700,
    650: $red-650,
    600: $red-600,
    550: $red-550,
    500: $red-500,
    450: $red-450,
    400: $red-400,
    350: $red-350,
    300: $red-300,
    250: $red-250,
    200: $red-200,
    150: $red-150,
    100: $red-100,
    50: $red-50,
    main: $red,
  ),
  pink: (
    950: $pink-950,
    900: $pink-900,
    850: $pink-850,
    800: $pink-800,
    750: $pink-750,
    700: $pink-700,
    650: $pink-650,
    600: $pink-600,
    550: $pink-550,
    500: $pink-500,
    450: $pink-450,
    400: $pink-400,
    350: $pink-350,
    300: $pink-300,
    250: $pink-250,
    200: $pink-200,
    150: $pink-150,
    100: $pink-100,
    50: $pink-50,
    main: $pink,
  ),
  fuchsia: (
    950: $fuchsia-950,
    900: $fuchsia-900,
    850: $fuchsia-850,
    800: $fuchsia-800,
    750: $fuchsia-750,
    700: $fuchsia-700,
    650: $fuchsia-650,
    600: $fuchsia-600,
    550: $fuchsia-550,
    500: $fuchsia-500,
    450: $fuchsia-450,
    400: $fuchsia-400,
    350: $fuchsia-350,
    300: $fuchsia-300,
    250: $fuchsia-250,
    200: $fuchsia-200,
    150: $fuchsia-150,
    100: $fuchsia-100,
    50: $fuchsia-50,
    main: $fuchsia,
  ),
  purple: (
    950: $purple-950,
    900: $purple-900,
    850: $purple-850,
    800: $purple-800,
    750: $purple-750,
    700: $purple-700,
    650: $purple-650,
    600: $purple-600,
    550: $purple-550,
    500: $purple-500,
    450: $purple-450,
    400: $purple-400,
    350: $purple-350,
    300: $purple-300,
    250: $purple-250,
    200: $purple-200,
    150: $purple-150,
    100: $purple-100,
    50: $purple-50,
    main: $purple,
  ),
  violet: (
    950: $violet-950,
    900: $violet-900,
    850: $violet-850,
    800: $violet-800,
    750: $violet-750,
    700: $violet-700,
    650: $violet-650,
    600: $violet-600,
    550: $violet-550,
    500: $violet-500,
    450: $violet-450,
    400: $violet-400,
    350: $violet-350,
    300: $violet-300,
    250: $violet-250,
    200: $violet-200,
    150: $violet-150,
    100: $violet-100,
    50: $violet-50,
    main: $violet,
  ),
  indigo: (
    950: $indigo-950,
    900: $indigo-900,
    850: $indigo-850,
    800: $indigo-800,
    750: $indigo-750,
    700: $indigo-700,
    650: $indigo-650,
    600: $indigo-600,
    550: $indigo-550,
    500: $indigo-500,
    450: $indigo-450,
    400: $indigo-400,
    350: $indigo-350,
    300: $indigo-300,
    250: $indigo-250,
    200: $indigo-200,
    150: $indigo-150,
    100: $indigo-100,
    50: $indigo-50,
    main: $indigo,
  ),
  blue: (
    950: $blue-950,
    900: $blue-900,
    850: $blue-850,
    800: $blue-800,
    750: $blue-750,
    700: $blue-700,
    650: $blue-650,
    600: $blue-600,
    550: $blue-550,
    500: $blue-500,
    450: $blue-450,
    400: $blue-400,
    350: $blue-350,
    300: $blue-300,
    250: $blue-250,
    200: $blue-200,
    150: $blue-150,
    100: $blue-100,
    50: $blue-50,
    main: $blue,
  ),
  azure: (
    950: $azure-950,
    900: $azure-900,
    850: $azure-850,
    800: $azure-800,
    750: $azure-750,
    700: $azure-700,
    650: $azure-650,
    600: $azure-600,
    550: $azure-550,
    500: $azure-500,
    450: $azure-450,
    400: $azure-400,
    350: $azure-350,
    300: $azure-300,
    250: $azure-250,
    200: $azure-200,
    150: $azure-150,
    100: $azure-100,
    50: $azure-50,
    main: $azure,
  ),
  cyan: (
    950: $cyan-950,
    900: $cyan-900,
    850: $cyan-850,
    800: $cyan-800,
    750: $cyan-750,
    700: $cyan-700,
    650: $cyan-650,
    600: $cyan-600,
    550: $cyan-550,
    500: $cyan-500,
    450: $cyan-450,
    400: $cyan-400,
    350: $cyan-350,
    300: $cyan-300,
    250: $cyan-250,
    200: $cyan-200,
    150: $cyan-150,
    100: $cyan-100,
    50: $cyan-50,
    main: $cyan,
  ),
  jade: (
    950: $jade-950,
    900: $jade-900,
    850: $jade-850,
    800: $jade-800,
    750: $jade-750,
    700: $jade-700,
    650: $jade-650,
    600: $jade-600,
    550: $jade-550,
    500: $jade-500,
    450: $jade-450,
    400: $jade-400,
    350: $jade-350,
    300: $jade-300,
    250: $jade-250,
    200: $jade-200,
    150: $jade-150,
    100: $jade-100,
    50: $jade-50,
    main: $jade,
  ),
  green: (
    950: $green-950,
    900: $green-900,
    850: $green-850,
    800: $green-800,
    750: $green-750,
    700: $green-700,
    650: $green-650,
    600: $green-600,
    550: $green-550,
    500: $green-500,
    450: $green-450,
    400: $green-400,
    350: $green-350,
    300: $green-300,
    250: $green-250,
    200: $green-200,
    150: $green-150,
    100: $green-100,
    50: $green-50,
    main: $green,
  ),
  lime: (
    950: $lime-950,
    900: $lime-900,
    850: $lime-850,
    800: $lime-800,
    750: $lime-750,
    700: $lime-700,
    650: $lime-650,
    600: $lime-600,
    550: $lime-550,
    500: $lime-500,
    450: $lime-450,
    400: $lime-400,
    350: $lime-350,
    300: $lime-300,
    250: $lime-250,
    200: $lime-200,
    150: $lime-150,
    100: $lime-100,
    50: $lime-50,
    main: $lime,
  ),
  yellow: (
    950: $yellow-950,
    900: $yellow-900,
    850: $yellow-850,
    800: $yellow-800,
    750: $yellow-750,
    700: $yellow-700,
    650: $yellow-650,
    600: $yellow-600,
    550: $yellow-550,
    500: $yellow-500,
    450: $yellow-450,
    400: $yellow-400,
    350: $yellow-350,
    300: $yellow-300,
    250: $yellow-250,
    200: $yellow-200,
    150: $yellow-150,
    100: $yellow-100,
    50: $yellow-50,
    main: $yellow,
  ),
  amber: (
    950: $amber-950,
    900: $amber-900,
    850: $amber-850,
    800: $amber-800,
    750: $amber-750,
    700: $amber-700,
    650: $amber-650,
    600: $amber-600,
    550: $amber-550,
    500: $amber-500,
    450: $amber-450,
    400: $amber-400,
    350: $amber-350,
    300: $amber-300,
    250: $amber-250,
    200: $amber-200,
    150: $amber-150,
    100: $amber-100,
    50: $amber-50,
    main: $amber,
  ),
  pumpkin: (
    950: $pumpkin-950,
    900: $pumpkin-900,
    850: $pumpkin-850,
    800: $pumpkin-800,
    750: $pumpkin-750,
    700: $pumpkin-700,
    650: $pumpkin-650,
    600: $pumpkin-600,
    550: $pumpkin-550,
    500: $pumpkin-500,
    450: $pumpkin-450,
    400: $pumpkin-400,
    350: $pumpkin-350,
    300: $pumpkin-300,
    250: $pumpkin-250,
    200: $pumpkin-200,
    150: $pumpkin-150,
    100: $pumpkin-100,
    50: $pumpkin-50,
    main: $pumpkin,
  ),
  orange: (
    950: $orange-950,
    900: $orange-900,
    850: $orange-850,
    800: $orange-800,
    750: $orange-750,
    700: $orange-700,
    650: $orange-650,
    600: $orange-600,
    550: $orange-550,
    500: $orange-500,
    450: $orange-450,
    400: $orange-400,
    350: $orange-350,
    300: $orange-300,
    250: $orange-250,
    200: $orange-200,
    150: $orange-150,
    100: $orange-100,
    50: $orange-50,
    main: $orange,
  ),
  sand: (
    950: $sand-950,
    900: $sand-900,
    850: $sand-850,
    800: $sand-800,
    750: $sand-750,
    700: $sand-700,
    650: $sand-650,
    600: $sand-600,
    550: $sand-550,
    500: $sand-500,
    450: $sand-450,
    400: $sand-400,
    350: $sand-350,
    300: $sand-300,
    250: $sand-250,
    200: $sand-200,
    150: $sand-150,
    100: $sand-100,
    50: $sand-50,
    main: $sand,
  ),
  grey: (
    950: $grey-950,
    900: $grey-900,
    850: $grey-850,
    800: $grey-800,
    750: $grey-750,
    700: $grey-700,
    650: $grey-650,
    600: $grey-600,
    550: $grey-550,
    500: $grey-500,
    450: $grey-450,
    400: $grey-400,
    350: $grey-350,
    300: $grey-300,
    250: $grey-250,
    200: $grey-200,
    150: $grey-150,
    100: $grey-100,
    50: $grey-50,
    main: $grey,
  ),
  zinc: (
    950: $zinc-950,
    900: $zinc-900,
    850: $zinc-850,
    800: $zinc-800,
    750: $zinc-750,
    700: $zinc-700,
    650: $zinc-650,
    600: $zinc-600,
    550: $zinc-550,
    500: $zinc-500,
    450: $zinc-450,
    400: $zinc-400,
    350: $zinc-350,
    300: $zinc-300,
    250: $zinc-250,
    200: $zinc-200,
    150: $zinc-150,
    100: $zinc-100,
    50: $zinc-50,
    main: $zinc,
  ),
  slate: (
    950: $slate-950,
    900: $slate-900,
    850: $slate-850,
    800: $slate-800,
    750: $slate-750,
    700: $slate-700,
    650: $slate-650,
    600: $slate-600,
    550: $slate-550,
    500: $slate-500,
    450: $slate-450,
    400: $slate-400,
    350: $slate-350,
    300: $slate-300,
    250: $slate-250,
    200: $slate-200,
    150: $slate-150,
    100: $slate-100,
    50: $slate-50,
    main: $slate,
  ),
);
