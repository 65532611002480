@use "~react-pdf/src/Page/TextLayer";
@use "~react-pdf/src/Page/AnnotationLayer";

$guidelines-width: 612px;

.guidelines-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    max-width: $guidelines-width;
  }
}

.guidelines-footer,
.guidelines-redirect {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .guidelines-inner {
    max-width: $guidelines-width;

    p {
      font-size: 0.875em;
      color: var(--pico-secondary);
    }
  }
}

.guidelines-redirect {
  p {
    margin: 4rem 0;
    font-variant-numeric: tabular-nums;
  }

  .loader {
    font-size: 1em;
  }
}
