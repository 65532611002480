/* HTML: <div aria-busy="true" class="loader"></div> */
.loader {
  display: inline-grid;
  font-size: 2rem;

  &:before,
  &:after {
    content: "🍻";
    grid-area: 1/1;
  }

  &:after {
    animation: loader 1s infinite;
  }
}

article {
  &:has(.loader) {
    aspect-ratio: 1/1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes loader {
  to {
    transform: scale(1.8);
    opacity: 0;
  }
}
