body,
html {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body > main {
  flex: 1;
}

.nav-logo {
  display: inline-block;
  line-height: 40px;
  vertical-align: middle;
  color: var(--pico-secondary);
  text-decoration: none;
}

.nav-icon {
  display: inline-block;
  margin-right: 0.5rem;
}

// Fix nav dropdown vertical alignment
nav details.dropdown summary:not([role]) {
  vertical-align: middle;
  line-height: 1.7rem;
}

.page-footer {
  color: var(--pico-secondary);
}
