@use "sass:map";
@use "../settings" as *;

@if map.get($modules, "layout/container") and $enable-classes {
  /**
   * Container
   */

  .container,
  .container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: var(#{$css-var-prefix}spacing);
    padding-left: var(#{$css-var-prefix}spacing);
  }

  .container {
    $first-breakpoint: true;
    @each $key, $values in $breakpoints {
      @if $values {
        @media (min-width: map.get($values, "breakpoint")) {
          max-width: map.get($values, "viewport");
          @if $first-breakpoint {
            $first-breakpoint: false;
            padding-right: 0;
            padding-left: 0;
          }
        }
      }
    }
  }
}
